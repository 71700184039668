import { ExitToApp, Instagram, WhatsApp } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/images/logo_header.png';
import { logout } from '../store/reducers/auth';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export default function Header({ exitApp = true }) {
  const dispatch = useDispatch();
  const logoutInProgress = useSelector((state) => state.auth.logoutInProgress);

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <Box
          sx={(theme) => ({
            flexGrow: 1,
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              justifyContent: 'center'
            }
          })}>
          <Box maxWidth={200}>
            <img src={logo} alt="Logo PICRONO" />
          </Box>
        </Box>

        <Button
          color="inherit"
          onClick={() => window.open('https://PiCrono.com.br/racetag/', '_blank')}>
          RESULTADOS
        </Button>

        <IconButton
          color="inherit"
          onClick={() => window.open('https://www.instagram.com/pi.crono/', '_blank')}>
          <Instagram />
        </IconButton>

        <IconButton
          color="inherit"
          onClick={() => window.open('https://wa.me/5514997103433', '_blank')}>
          <WhatsApp />
        </IconButton>

        {exitApp ? (
          <IconButton
            disabled={logoutInProgress}
            color="inherit"
            onClick={() => dispatch(logout())}>
            {logoutInProgress ? <CircularProgress /> : <ExitToApp />}
          </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  exitApp: PropTypes.bool.required
};
